import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section style={{ paddingTop: "300px", paddingBottom: "150px" }}>
      <div className="container-fluid">
        <div className="mx-auto text-center">
          <h1>NOT FOUND</h1>
          <p>The page you are looking for does not exist.</p>
          <div className="mt-4">
            <Link className="re-btn-2 py-3" to="/">
              Back to home
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
